.transparent ion-toolbar {
  --background: transparent !important;
  --ion-color-base: transparent !important;

  &:first-of-type {
    padding-top: 0 !important;
  }
}

.header {
  display: flex;
  align-content: center;
  padding: 8px;
}
