.full-height {
  height: calc(100vh - 2em);
  min-height: calc(100vh - 2em);
  display: flex;
  flex-direction: column;
}

.ion-page.no-navigation .full-height {
  height: 100%;
  min-height: inherit;
}

.full-height-container {
  position: relative;

  .full-height {
    height: 100%;
    min-height: inherit;
  }
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}

.row-grow {
  flex-grow: 1;
}

.stick-bottom {
  margin-top: auto;
}

.ion-wrap-reverse-lg-up {
  @media only screen and (min-width: 768px

    /* var(--ion-grid-width-md, 768px) */) {
    flex-wrap: wrap-reverse;
  }
}

@mixin grid($name, $media) {
  @media only screen and #{$media} {
    .ion-align-items-start-#{$name} {
      align-items: start;
    }
    .ion-align-items-center-#{$name} {
      align-items: center;
    }
    .ion-align-items-end-#{$name} {
      align-items: end;
    }
    .ion-justify-content-start-#{$name} {
      justify-content: start;
    }
    .ion-justify-content-center-#{$name} {
      justify-content: center;
    }
    .ion-justify-content-around-#{$name} {
      justify-content: around;
    }
    .ion-justify-content-between-#{$name} {
      justify-content: between;
    }
    .ion-justify-content-end-#{$name} {
      justify-content: end;
    }
  }
}

@include grid('sm', '(min-width: 0) and (max-width: 575px)');
@include grid('md', '(min-width: 576px) and (max-width: 767px)');
@include grid('lg', '(min-width: 768px) and (max-width: 991px)');
@include grid('xl', '(min-width: 992px) and (max-width: 9999px)');

@include grid('sm-up', '(min-width: 0)');
@include grid('md-up', '(min-width: 576px)');
@include grid('lg-up', '(min-width: 768px)');
@include grid('xl-up', '(min-width: 992px)');

@include grid('sm-down', '(max-width: 575px)');
@include grid('md-down', '(max-width: 767px)');
@include grid('lg-down', '(max-width: 991px)');
@include grid('xl-down', '(max-width: 9999px)');
