.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
}
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios {
  white-space: normal;
}
.alert-tappable.alert-checkbox {
  height: auto;
  contain: content;
}

.sc-ion-alert-ios-h {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.8);
}
