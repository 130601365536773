.list-md ion-item:last-child,
.list-ios ion-item:last-child {
  --border-width: 0 !important;
}

.list-md ion-item:last-child,
.list-ios ion-item:last-child {
  --border-width: 0 !important;
  --inner-border-width: 0 !important;
}

.list-md,
.list-ios {
  padding-top: 0;
  padding-bottom: 0;

  &.ios {
    border-radius: 10px !important;
  }

  &.md {
    border-radius: 4px !important;
  }
}

ion-list.no-background .item {
  --background: none !important;
}

.ion-text-right ion-button:last-child {
  padding-right: 0;
}

.list-ios.list-inset {
  margin: 0;
}

.cards-list {
  &.with-pagination {
    padding-bottom: 2em;
  }

  ion-card {
    text-align: left;
    display: flex;

    img {
      display: block;
    }

    .background {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 70%;
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      text-align: start;
      background-image: linear-gradient(
        to bottom,
        rgba(var(--ion-black-rgb), 0),
        rgba(var(--ion-black-rgb), 0.8)
      );

      &.top-background {
        height: 20%;
        top: 0;
        bottom: inherit;
        background-image: linear-gradient(
          to top,
          rgba(var(--ion-black-rgb), 0),
          rgba(var(--ion-black-rgb), 0.9)
        );
      }
    }

    .bottom-info {
      height: 100%;
    }

    .bottom-info,
    .background {
      color: var(--ion-white) !important;

      .title {
        font-weight: 600;

        h4 {
          font-weight: 800;
        }
      }
    }

    .background .title {
      text-shadow: 1px 1px 0 var(--ion-black);
      font-size: 1.2em;
      font-weight: 800;

      h4 {
        font-size: inherit;
        font-size: 1.2em;
        font-weight: 600;
      }
    }
  }
}
