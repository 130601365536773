.html:not(.search-result) {
  * {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: var(--ion-margin);
  }

  h1 {
    font-size: 1.3em;
  }
  h2 {
    font-size: 1.2em;
  }
  h3 {
    font-size: 1.2em;
    font-weight: bold;
    color: var(--ion-color-primary);
  }
  h4 {
    font-size: 1em;
  }

  ul {
    margin: 0;
    padding-left: 1em;
  }
}
