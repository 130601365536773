:root {
  --ion-safe-area-bottom: 0;
  --ion-safe-area-top: 0;
  --ion-statusbar-padding: 1em;
  --ion-toolbar-background: rgba(243, 243, 243, 0.9);
}

.loading-wrapper {
  box-shadow: none !important;
}

ion-title.title-default:first-of-type {
  text-align: center;
}

ion-title.title-default:not(:first-of-type) {
  font-size: 1.7em;

  small {
    font-size: 100%;
  }
}

ion-header:after {
  display: none;
}
