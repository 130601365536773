.settings {
  .header,
  ion-header {
    .avatar-spinner {
      position: absolute;
      background: var(--ion-color-light);
      opacity: 0.5;
      height: 100%;
      width: 100%;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ion-avatar {
      position: relative;
      height: 70px;
      width: 70px;
      background: var(--ion-color-secondary-tint);
      display: flex;
      justify-content: center;
      align-items: center;
      margin-inline-start: 2px;
      margin-inline-end: 1em;

      & > ion-img {
        width: 70px;
        height: 70px;
      }

      svg {
        height: 40px;
        width: 40px;
        color: var(--ion-color-light);
      }
    }
  }

  .chart {
    width: 200px;
    height: 200px;
    position: relative;
  }
}
