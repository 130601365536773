ion-badge {
  --padding-top: calc(var(--ion-padding) / 2);
  --padding-bottom: calc(var(--ion-padding) / 2);
  --padding-start: calc(var(--ion-padding) / 1.5);
  --padding-end: calc(var(--ion-padding) / 1.5);
  border-radius: 50px;

  &.filter-badge {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-end: 2px;

    .type-icon {
      --padding-start: 8px;
      --padding-end: 8px;
    }
  }
}

.button-badge {
  --border-radius: 50px;
  height: inherit;

  ion-badge {
    position: absolute;
    z-index: 10;
    padding: 6px;
    max-width: 10px;
    right: 6px;
    bottom: calc(50% + 2px);
    font-size: 12px;
    line-height: 16px;
  }
}
