#root {
  white-space: pre-line;
}

.ion-page:not(.introduction) > ion-content {
  --padding-start: var(--ion-padding);
  --padding-end: var(--ion-padding);
  --padding-bottom: var(--ion-padding);
}

ion-spinner.small {
  transform: scale(0.6);
}

ion-loading {
  --backdrop-opacity: 1 !important;
  --background: var(--ion-backdrop-color) !important;
  --spinner-color: var(--ion-color-primary) !important;
}

hr {
  border-top: 1px solid var(--ion-border-color);
}

.ion-border {
  border: 1px solid var(--ion-border-color);
}

.pointer {
  cursor: pointer;
}

.no-background {
  background: none !important;
}

ion-popover {
  --min-width: 300px;
  --max-width: 90vh;
}

.price {
  width: 100%;
  display: flex;
  align-items: center;

  h5 {
    border-right: 1px solid var(--ion-color-light);
  }

  &:hover {
    background: var(--ion-color-secondary);
  }
}

.fit-content {
  max-width: fit-content !important;
}

.CookieConsent {
  background: var(--ion-color-light) !important;
  color: var(--ion-color-medium) !important;
  justify-content: center !important;

  & div:first-child {
    flex: inherit !important;
  }

  a {
    margin-inline-start: calc(var(--ion-padding) / 1.5);
    text-decoration: none;
  }

  button {
    background: var(--ion-color-light) !important;
    border: 1px solid var(--ion-color-medium) !important;
    color: var(--ion-color-medium) !important;
    border-radius: 10px !important;
    padding: calc(var(--ion-padding) / 1.5) !important;
  }
}

.lng-fr .text-en,
.lng-en .text-fr {
  display: none;
}

.bg-light {
  background: var(--ion-color-light) !important;
}

.ion-primary {
  color: var(--ion-color-primary);
}
.ion-color-medium {
  color: var(--ion-color-medium);
}

.absolute {
  position: absolute;
  z-index: 1000;
}
