.ion-margin-auto {
  margin: auto !important;
}

.ion-no-margin {
  margin: 0 !important;
}

.ion-no-margin-horizontal {
  margin-inline: 0 !important;
}

.ion-no-margin-vertical {
  margin-block: 0 !important;
}

.ion-no-margin-top {
  margin-top: 0 !important;
}

.ion-no-margin-bottom {
  margin-bottom: 0 !important;
}

.ion-no-margin-start {
  margin-inline-start: 0 !important;
}

.ion-no-margin-end {
  margin-inline-end: 0 !important;
}

.ion-no-padding {
  padding: 0 !important;
}

.ion-no-padding-horizontal {
  padding-inline: 0 !important;
}

.ion-no-padding-vertical {
  padding-block: 0 !important;
}

.ion-no-padding-top {
  padding-top: 0 !important;
}

.ion-no-padding-bottom {
  padding-bottom: 0 !important;
}

.ion-no-padding-start {
  padding-inline-start: 0 !important;
}

.ion-no-padding-end {
  padding-inline-end: 0 !important;
}

.ion-margin-half {
  margin: 8px;
}

.ion-margin-horizontal-half {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.ion-margin-vertical-half {
  margin-top: 8px;
  margin-bottom: 8px;
}

.ion-margin-vertical-2x {
  margin-top: 16px;
  margin-bottom: 16px;
}

.ion-margin-top-half {
  margin-top: 8px;
}

.ion-margin-bottom {
  margin-bottom: var(--ion-margin);
}

.ion-margin-bottom-half {
  margin-bottom: 8px;
}

.ion-margin-start-half {
  margin-inline-start: 8px;
}

.ion-margin-end {
  margin-inline-end: var(--ion-margin) !important;
}

.ion-margin-end-half {
  margin-inline-end: 8px;
}

.ion-padding-half {
  padding: 8px;
}

.ion-padding-horizontal-half {
  padding: 0 8px;
}

.ion-padding-vertical-half {
  padding: 8px 0;
}

.ion-padding-top-half {
  padding-top: 8px;
}

.ion-padding-bottom-half {
  padding-bottom: 8px;
}

.ion-padding-start-half {
  padding-inline-start: 8px;
}

.ion-padding-end-half {
  padding-inline-end: 8px;
}

@mixin spacing($name, $media) {
  @media only screen and #{$media} {
    @for $i from 0 through 10 {
      .ion-padding-#{$i}x-#{$name} {
        padding: calc(#{$i} * var(--ion-padding));
      }

      .ion-padding-vertical-#{$i}x-#{$name} {
        padding: calc(#{$i} * var(--ion-padding)) 0;
      }

      .ion-padding-horizontal-#{$i}x-#{$name} {
        padding: 0 calc(#{$i} * var(--ion-padding));
      }

      .ion-padding-top-#{$i}x-#{$name} {
        padding-top: calc(#{$i} * var(--ion-padding));
      }

      .ion-padding-end-#{$i}x-#{$name} {
        padding-inline-end: calc(#{$i} * var(--ion-padding));
      }

      .ion-padding-bottom-#{$i}x-#{$name} {
        padding-bottom: calc(#{$i} * var(--ion-padding));
      }

      .ion-padding-start-#{$i}x-#{$name} {
        padding-inline-start: calc(#{$i} * var(--ion-padding));
      }

      .ion-margin-#{$i}x-#{$name} {
        margin: calc(#{$i} * var(--ion-margin));
      }

      .ion-margin-vertical-#{$i}x-#{$name} {
        margin: calc(#{$i} * var(--ion-margin)) 0;
      }

      .ion-margin-horizontal-#{$i}x-#{$name} {
        margin: 0 calc(#{$i} * var(--ion-margin));
      }

      .ion-margin-top-#{$i}x-#{$name} {
        margin-top: calc(#{$i} * var(--ion-margin));
      }

      .ion-margin-end-#{$i}x-#{$name} {
        margin-inline-end: calc(#{$i} * var(--ion-margin));
      }

      .ion-margin-bottom-#{$i}x-#{$name} {
        margin-bottom: calc(#{$i} * var(--ion-margin));
      }

      .ion-margin-start-#{$i}x-#{$name} {
        margin-inline-start: calc(#{$i} * var(--ion-margin));
      }
    }
  }
}

@include spacing('sm', '(min-width: 0) and (max-width: 575px)');
@include spacing('md', '(min-width: 576px) and (max-width: 767px)');
@include spacing('lg', '(min-width: 768px) and (max-width: 991px)');
@include spacing('xl', '(min-width: 992px) and (max-width: 9999px)');

@include spacing('sm-up', '(min-width: 0)');
@include spacing('md-up', '(min-width: 576px)');
@include spacing('lg-up', '(min-width: 768px)');
@include spacing('xl-up', '(min-width: 992px)');

@include spacing('sm-down', '(max-width: 575px)');
@include spacing('md-down', '(max-width: 767px)');
@include spacing('lg-down', '(max-width: 991px)');
@include spacing('xl-down', '(max-width: 9999px)');
