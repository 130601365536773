.introduction {
  height: 100%;
  overflow: hidden;

  ion-icon {
    font-size: 64px;
  }

  ion-button ion-icon {
    font-size: 1.3em;
  }

  .swiper {
    overflow: hidden;

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: 0 !important;
      padding: inherit;
    }

    h1 {
      font-size: 1.5em;
    }

    .main-row {
      flex-grow: 1;
    }

    .actions-row {
      padding-bottom: calc(var(--ion-padding) * 2);
    }

    .welcome-slide {
      .logo {
        width: 60px;
      }
      .illustration {
        width: 100%;
        max-width: inherit;
        height: 100%;
        max-height: inherit;
      }
    }

    .categories-slide {
      .categories-container {
        margin-top: var(--ion-margin);

        .cards-list {
          max-height: calc(100vh - 260px);
          overflow-y: scroll;
        }

        ion-card.selected {
          border: 4px solid var(--ion-color-primary);
        }
      }
    }
  }
}
