ion-img {
  width: 100%;
}

.img-sm {
  max-width: 200px;
}

.img-md {
  max-width: 420px;
}