ion-avatar {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;

  &:not(.transparent) {
    background-color: white;
    text-align: center;
    color: white;
  }

  .upload-btn-wrapper {
    border-radius: 50px;
  }

  ion-img {
    width: 60px;
    height: 60px;
  }

  &.avatar-absolute {
    position: absolute;
    margin: calc(var(--ion-margin) / 2);
  }

  & > * {
    width: 100%;
    font-size: 1.4em;
  }
}

.avatar-xs {
  width: 22px;
  height: 22px;

  ion-img {
    width: 22px;
    height: 22px;
  }

  & > b {
    font-size: 1em;
  }
}

.avatar-sm {
  width: 30px;
  height: 30px;

  ion-img {
    width: 30px;
    height: 30px;
  }

  & > b {
    font-size: 1em;
  }
}

.avatar-md {
  width: 40px;
  height: 40px;

  ion-img {
    width: 40px;
    height: 40px;
  }

  & > b {
    font-size: 1.2em;
  }
}

.avatar-lg {
  width: 80px;
  height: 80px;

  ion-img {
    width: 80px;
    height: 80px;
  }

  & > b {
    font-size: 1.6em;
  }
}

.avatar-xl {
  width: 100px;
  height: 100px;

  ion-img {
    width: 100px;
    height: 100px;
  }

  & > b {
    font-size: 1.6em;
  }
}

.avatar-xxl {
  width: 150px;
  height: 150px;

  ion-img {
    width: 150px;
    height: 150px;
  }

  & > b {
    font-size: 1.6em;
  }
}
