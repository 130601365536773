ion-tab-bar {
  border-top: 1px solid var(--ion-border-color);
  background: var(--ion-tab-bar-background);
}

@media only screen and (min-width: 576px

  /* var(--ion-grid-width-sm, 576px) */) {
  ion-header ion-toolbar:first-of-type {
    padding-top: 0;
  }

  ion-tab-bar {
    position: relative;
    bottom: 0;
    height: 100vh;
    width: 200px;
    padding: var(--ion-padding) var(--ion-padding) 0;
    margin: 0;
    flex-direction: column;
    justify-content: flex-start;
    background: var(--ion-tab-bar-background);
    --background: var(--ion-tab-bar-background);
    border-right: 1px solid var(--ion-border-color);
    border-top: 0;

    ion-tab-button {
      width: 100%;
      max-height: 50px;
      width: 100%;
      margin-bottom: var(--ion-margin);
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      align-items: left;
      text-align: left;
      border: 0 !important;
      border-radius: 0 !important;
      font-weight: 500;

      &.logo {
        max-height: 80px;
      }

      .main-title {
        color: var(--ion-text-color);
        font-size: 2em;
      }
    }

    p,
    svg {
      margin: 0;
    }

    p {
      width: 100%;
      font-size: initial;
      margin-left: 8px;
      color: var(--ion-color-dark);
    }

    .lang-switcher {
      font-size: 1em;
      margin-top: auto;

      ion-select {
        --padding-start: 0.5em;
        --padding-bottom: 3em;
      }
    }
  }

  .tabs-inner {
    position: initial !important;
    contain: initial !important;
    margin: 0;
  }

  ion-app:not(.no-navigation) ion-router-outlet > .ion-page > ion-header > * {
    width: calc(100vw - 230px);
    margin-left: 230px;
  }

  ion-app:not(.no-navigation) ion-router-outlet > .ion-page > ion-content > * {
    position: absolute;
    width: calc(100% - 260px);
    max-width: 991px /* var(--ion-grid-width-lg, 992px) */;
    left: 240px;

    &.page-content {
      top: calc(50px + 3em);

      &.with-header-lg {
        top: 150px;
      }
    }
  }
}

@media only screen and (min-width: 576px
  /* var(--ion-grid-width-sm, 576px) */) and (max-width: 991px

  /* var(--ion-grid-width-lg, 992px) */) {
  ion-tab-bar {
    width: 90px;
    margin: 0;
    padding: 32px 0 0;

    ion-tab-button {
      justify-content: center;
      background: transparent;
      --background: transparent;
    }
  }

  ion-app:not(.no-navigation) ion-router-outlet > .ion-page > ion-header > * {
    width: calc(100% - 90px);
    margin-left: 90px;
  }

  ion-app:not(.no-navigation) ion-router-outlet > .ion-page > ion-content > * {
    position: relative;
    width: calc(100% - 100px);
    left: calc(110px - var(--ion-padding));
    margin: 0;

    &.page-content,
    &.page-content.with-header-lg {
      top: 0;
    }
  }
}

@media only screen and (min-width: 992px

  /* var(--ion-grid-width-lg, 992px) */) {
  ion-badge {
    right: 2px;
    left: auto;
    width: auto;
  }
  ion-app:not(.no-navigation) ion-router-outlet > .ion-page > ion-content > * {
    position: relative;
    width: calc(100% - 250px);
    left: calc(255px - var(--ion-padding));
    margin: 0;

    &.page-content,
    &.page-content.with-header-lg {
      top: 0;
    }
  }
}

.tab-bar {
  svg {
    margin: auto;
    justify-self: center;
    stroke: var(--ion-color-dark);
    font-size: 1.1em;
    width: 4em;
    min-width: 30px;
  }
}

ion-avatar.with-picture {
  box-shadow: 0 0 0 2px var(--ion-color-dark);
  margin-inline-end: 4px;
}

.tab-bar .tab-selected {
  p {
    color: var(--ion-color-primary);
  }

  ion-avatar.with-picture {
    box-shadow: 0 0 0 2px var(--ion-color-primary);
  }

  svg {
    stroke: var(--ion-color-primary);
  }
}
