.ion-activated {
  transform: none;
}

ion-card {
  margin: 0;
  border: 1px solid var(--ion-border-color);

  &.ios:not(.input) {
    border-radius: 10px;
  }

  box-shadow: none;

  ion-card-content {
    position: relative;
  }

  .card-title {
    margin: var(--ion-margin);

    h3 {
      color: var(--ion-color-dark) !important;
      font-weight: 700;
    }
  }
}

form ion-card {
  min-height: inherit;
}

ion-card-header {
  position: absolute;
  width: 100%;
  padding: 0;
}
