ion-button {
  text-transform: none;
  cursor: pointer;
}

.button-link {
  color: var(--ion-color-primary);
}

.button-small {
  font-size: 13px !important;
}

.list-ios .type-button {
  --padding-start: var(--ion-padding) !important;
  --padding-end: var(--ion-padding) !important;
}

ion-button.type-icon {
  margin-top: 0;
  margin-bottom: 0;
  --padding-start: var(--ion-padding);
  --padding-end: var(--ion-padding);

  &[fill='clear'],
  &[size='small'] {
    --padding-start: calc(var(--ion-padding) / 3);
    --padding-end: calc(var(--ion-padding) / 3);
  }
}

.submit-enter {
  position: absolute;
  visibility: hidden;
  left: -1000px;
}

.follow-absolute {
  position: absolute;
  right: 0;
  margin: calc(var(--ion-margin) / 2);
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;

  input[type='file'] {
    font-size: 100px;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.button-large {
  font-size: 16px;
}
