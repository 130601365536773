@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

body {
  overflow-wrap: break-word;
}

.main-title {
  font-family: 'Pacifico', cursive;
  font-size: 1.5em;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 2em;
}

h1.main-title {
  font-weight: 400 !important;
}

a {
  cursor: pointer;
}

.b {
  font-weight: bold !important;
}

.u {
  text-decoration: underline;
}

pre {
  font-family: inherit;
  white-space: pre-wrap;
}

.link {
  color: var(--ion-color-primary);
  cursor: pointer;
}

.long-text {
  line-height: 1.5em;
}

.ion-text-break {
  white-space: break-spaces;

  button {
    color: red;
  }
}

ion-title.title-large {
  font-size: 1.7em;
  padding-inline: 0;
}

.header-lg {
  ion-title.title-large {
    line-height: 0.8em;
    align-items: center;

    ion-note {
      font-weight: 400;
      font-size: 0.6em;
    }
  }
}

ion-note {
  font-size: 0.7em;
}

ion-label,
ion-note {
  white-space: normal !important;
}

.ion-label {
  font-size: inherit !important;
  --padding-start: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;

  .button-native {
    padding: 0 !important;
  }
}

.h1 {
  font-size: 26px;
}

.h6 {
  font-size: 16px;
}

.ion-color-white {
  color: var(--ion-white) !important;
}
