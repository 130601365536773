.swiper {
  height: 100%;
  width: 100%;

  .swiper-wrapper {
    align-items: stretch;

    .swiper-slide {
      height: auto;
    }
  }

  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0px;
    padding: inherit;
  }

  ion-avatar {
    img,
    ion-img {
      display: block;
      height: 100%;
      width: 100%;
    }
  }
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  padding-bottom: 30px !important;
  bottom: -30px !important;
}

.swiper .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background: var(--ion-color-dark);
}
