ion-searchbar {
  .searchbar-input-container {
    input {
      font-size: 14px !important;
    }
  }
}

.sc-ion-searchbar-ios-h {
  --placeholder-opacity: 0.5;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.search-result {
  color: var(--ion-color-medium);

  b {
  color: var(--ion-color-dark);
  }
}