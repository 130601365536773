ion-modal {
  --max-width: 1080px;
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
  --backdrop-opacity: 0.9;
  margin: auto;

  &.modal-sheet {
    --border-radius: 10px;
  }

  &.modal-sm {
    --max-width: 640px;
    --height: 50%;
  }

  .with-header-lg {
    top: 60px;
  }

  &.donate-form,
  &.confirm-payment {
    #ion-react-wrapper {
      display: block !important;

      .prices {
        max-width: 575px /* var(--ion-grid-width-sm, 576px) */;
      }
    }
  }

  .modal-navigation {
    position: absolute;
    width: 100%;
    height: 60px;
    z-index: 1000;
    padding: var(--ion-padding);

    @media only screen and (min-width: 768px
      /* var(--ion-grid-width-md, 768px) */) {
      position: relative;
    }

    ion-button {
      height: 2em;
      --padding-start: 5px;
      --padding-end: 5px;
    }
  }

  .main-image {
    @media only screen and (max-width: 768px
      /* var(--ion-grid-width-md, 768px) */) {
      margin: 0;
      border-radius: 0 !important;
      border: 0 !important;
    }
  }

  &.content-modal {
    ion-content {
      --padding-bottom: calc(var(--ion-padding) * 2);
    }
    ion-card-content {
      color: var(--ion-color-dark) !important;

      p {
        margin-bottom: 8px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 32px 0 16px;
      }
    }
  }
}

ion-modal::part(backdrop) {
  background: var(--ion-color-medium);
}
