
.shining-box {
  position: relative;

  &:after {
    content: '';
    top: 0;
    transform: translateX(100%);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    animation: slide 1.5s ease-in-out 15s infinite;

    /*
    CSS Gradient - complete browser support from http://www.colorzilla.com/gradient-editor/
    */
    background: -moz-linear-gradient(105deg, rgba(var(--ion-card-shining-rgb), 0) 20%, rgba(var(--ion-card-shining-rgb), 0.4) 50%, rgba(var(--ion-card-background-rgb), 0) 99%, rgba(125, 185, 232, 0) 80%);
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(105deg, rgba(var(--ion-card-shining-rgb), 0) 20%, rgba(var(--ion-card-shining-rgb), 0.4) 50%, rgba(var(--ion-card-background-rgb), 0) 99%, rgba(125, 185, 232, 0) 80%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(105deg, rgba(var(--ion-card-shining-rgb), 0) 20%, rgba(var(--ion-card-shining-rgb), 0.4) 50%, rgba(var(--ion-card-background-rgb), 0) 99%, rgba(125, 185, 232, 0) 80%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(105deg, rgba(var(--ion-card-shining-rgb), 0) 20%, rgba(var(--ion-card-shining-rgb), 0.4) 50%, rgba(var(--ion-card-background-rgb), 0) 99%, rgba(125, 185, 232, 0) 80%);
    /* IE10+ */
    background: linear-gradient(105deg, rgba(var(--ion-card-shining-rgb), 0) 20%, rgba(var(--ion-card-shining-rgb), 0.4) 50%, rgba(var(--ion-card-background-rgb), 0) 99%, rgba(125, 185, 232, 0) 80%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#007db9e8', GradientType=1);
    /* IE6-9 */
  }

  /* animation */

  @keyframes slide {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }
}