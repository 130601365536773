.help {
  ion-icon {
    font-size: 64px;
  }

  ion-button ion-icon {
    font-size: 1.3em;
  }

  .swiper {
    overflow: hidden;

  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: 0 !important;
    padding: inherit;
  }

    h1 {
      font-size: 1.5em;
    }

    .categories-slide {
      padding-top: 2em;
      .categories-container {

        ion-card.selected {
          border: 4px solid var(--ion-color-primary);
        }

        small {
          font-size: 0.7em;
        }
      }
    }

    .countries-slide {
      padding-top: 2em;

      .countries-container {
        ion-item.selected {
          --background: var(--ion-color-primary-tint);
        }

        small {
          font-size: 0.7em;
        }
      }
    }
  }
}