.input {
  padding: 0.5em 1em;
  margin-left: unset;
  margin-right: unset;
  margin-inline-start: var(--ion-margin);
  margin-inline-end: var(--ion-margin);
  margin-bottom: var(--ion-margin);
  border-radius: 40px;
}

  .input-sm {
    --padding-top: 4px !important;
    --padding-bottom: 4px !important;
  }

  input:read-only {
    opacity: 0.5;
  }

  .error {
    font-size: smaller;
    padding: 1em;
  }

  .sc-ion-input-md-h {
    --padding-top: 14px;
  }
  @media only screen and (min-width: 576px /* var(--ion-grid-width-sm, 576px) */) {
  .label-fixed.sc-ion-label-md-h {
    flex: inherit;
    width: inherit;
    max-width: 400px;
  }
}

.form-item-input {
  margin: 0;

  &.item-select {
    max-width: calc(100% - var(--ion-padding));

    ion-select {
      width: 100%;
      max-width: inherit;
    }
  }
}