ion-item {
  cursor: pointer;

  &.transparent {
    --background: none;
    --background-hover: none;
    --background-focused: none;
    --inner-border-width: 0;
    --border-radius: 0;
    --padding-start: 0;
    --padding-end: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
  }
}

.empty-list {
  min-height: 200px;
}

ion-item-divider {
  --padding-top: var(--ion-padding);

  &[color='light'] {
    color: var(--ion-color-medium);
  }
}
