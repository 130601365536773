.table {
  .header {
    font-weight: 600;
    border-bottom: 1px solid var(--ion-color-dark);
  }

  ion-row {
    border-bottom: 1px solid var(--ion-color-medium);

    ion-col {
      padding: 1em;
    }
  }
}